import { nanoid } from 'nanoid'
import { type StateCreator, create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { en, fr } from '#app/locales/index.ts'
import { type AldoWiseState, type Conversation } from '@/appTypes'

// const createExcerpt = (str: string) => str.substring(0, 10) + '...'

const defaultPrompts = [
	{
		title: 'I am working for a fashion footwear…',
		prompt:
			'I am working for a fashion footwear and I want to summarize this following text:\n\n',
	},
	{
		title: 'I am writing a profesional email...',
		prompt: 'Write a professional email to:\n\n',
	},
]

const myMiddlewares = (f: StateCreator<AldoWiseState>) =>
	devtools(
		persist(f, {
			name: 'awStore',
			partialize: state => ({
				folders: state.folders,
				conversations: state.conversations,
				prompts: state.prompts,
			}),
		}),
	)

const createConversation = (locale: string, model: string) => {
	return {
		id: nanoid(),
		name:
			locale === 'en'
				? en.default.sidebar.newConversation
				: fr.default.sidebar.newConversation,
		messages: [],
		model: model,
	} as Conversation
}

export const aldoWiseStore = create<AldoWiseState>()(
	myMiddlewares((set, get) => ({
		selectedModel: 'chatgpt',
		folders: [],
		prompts: defaultPrompts,
		selectedConversation: undefined,
		conversations: [],
		content: '',
		menuIsHidden: true,
		toggleMenu: () => {
			const menuState = get().menuIsHidden
			return set({ menuIsHidden: !menuState })
		},
		selectModel: (locale, model) => {
			set({
				selectedModel: model,
			})
		},
		createConversation: locale => {
			const selectedModel = get().selectedModel
			const conversations = get().conversations
			const newConvo = createConversation(locale, selectedModel)
			set({
				selectedConversation: newConvo,
				conversations: [...conversations, newConvo],
			})
		},
		selectConversation: (id: string) => {
			const selectedConversation = get().conversations.find(
				conversation => conversation.id === id,
			)
			if (typeof selectedConversation === 'undefined') {
				set({
					selectedConversation: selectedConversation,
					selectedModel: get().selectedModel,
				})
			} else {
				const selectedModel = selectedConversation.model
				set({ selectedConversation: selectedConversation, selectedModel })
			}
		},
		clearAllConversations: () => {
			set({ conversations: [] })
		},
		deleteConversation: (id: string) => {
			const conversations = get().conversations
			const conversationsWithoutId = conversations.filter(
				convo => convo.id !== id,
			)
			set({
				conversations: conversationsWithoutId,
				selectedConversation: undefined,
			})
		},
		addMessage: (locale, message) => {
			let selectedConversation = get().selectedConversation
			const model = get().selectedModel
			if (typeof selectedConversation === 'undefined') {
				selectedConversation = createConversation(locale, model)
				selectedConversation = {
					id: nanoid(),
					name: 'New Conversation',
					messages: [],
					model: model,
				} as Conversation
			}
			let title = selectedConversation.name
			if (message.role === 'user') {
				title = message.content
			}
			const conversations = get().conversations
			let updatedConversation: Conversation = {
				...selectedConversation,
				name: title,
				messages: [...selectedConversation!.messages, message],
			}

			const updateConversations = conversations.reduce(
				(arr: Conversation[], item) => {
					let itemsFromSecondArray = [updatedConversation].filter(
						({ id }) => id == item.id,
					)
					if (itemsFromSecondArray.length) {
						arr.push(...itemsFromSecondArray)
					} else arr.push(item)
					return arr
				},
				[],
			)
			set({
				conversations: updateConversations,
				selectedConversation: updatedConversation,
			})
		},
		setContent: (content: string) => {
			set({ content })
		},
	})),
)
